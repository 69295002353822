import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import Link from '../../AppLink';
import GiftIcon from '../../../icons/gift.svg';

// Router
import { paths, getRoute } from '../../../entry/routes';

import styles from './RewardItem.styl';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

export default function RewardItem(props) {
  const { message, applied, goods, code, idx, allConditionsChecked, reward } = props;
  const hasGoods = goods.length > 0;
  const isGood = reward === 'good';
  const Component = isGood && allConditionsChecked && !applied ? Link : 'div';
  const to = hasGoods ? getRoute(paths.stockGifts, idx, code) : getRoute(paths.home);

  return (
    <Component
      className={cx('RewardItem', {
        RewardItem_applied: applied,
        RewardItem_avaivable: allConditionsChecked && !applied,
      })}
      to={to}
    >
      <div className={cx('RewardItem__content')}>
        <div className={cx('RewardItem__message')}>
          <GiftIcon className={cx('RewardItem__icon')} />
          {message}
        </div>
        {(goods.length > 1 || goods[0]?.variations?.length > 1) && applied && (
          <Link to={`${to}?replace`} className={cx('RewardItem__replace')}>
            <FormattedMessage id="promocode.reward.replace" />
          </Link>
        )}
      </div>
    </Component>
  );
}

RewardItem.defaultProps = {
  allConditionsChecked: false,
  goods: [],
};

RewardItem.propTypes = {
  message: PropTypes.string.isRequired,
  reward: PropTypes.string.isRequired,
  applied: PropTypes.bool.isRequired,
  allConditionsChecked: PropTypes.bool,
  goods: PropTypes.array,
  code: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
};
