import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

//Utils
import { setGlobalMessage } from '../../utils/setGlobalMessage';

// State
import { applyStock, clearStock, clearStockErrors } from '../../state/modules/stock/actions';
import {
  getConditionsSelector,
  getIsAllConditionsCheckedSelector,
  getProgressSelector,
  getRewardsSelector,
} from '../../state/modules/stock/selectors';

// Components
import CheckItem from './CheckItem';
import RewardItem from './RewardItem';
import PromoArtefact from '../../scenes/Home/PromoGame/PromoArtefact';

// Icons
import ClearIcon from '../../icons/delete.svg';

// Styles
import styles from './PromocodeWidget.styl';
import FlagsDecor from '../../scenes/Home/PromoGame/FlagsDecor/FlagsDecor';
import { themeNameSpaces } from '../../state/modules/city/themeNameSpaces';
import { isMovieBorderTheme } from '../../state/modules/city/selectors';
import PromotionMovieBorderDecor from '../PromotionMovieBorder/PromotionMovieBorderDecor';
import { useLocation } from 'react-router-dom';
import { getRoute, paths } from '../../entry/routes';

const cx = classNames.bind(styles);

export default function PromocodeWidget(props) {
  const { className, inputClassname, withProgressbar, isDesktop, showDecor } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    isApplying,
    isApplied,
    error,
    data: { code },
  } = useSelector(state => state?.stock);
  const conditions = useSelector(getConditionsSelector);
  const rewards = useSelector(getRewardsSelector);
  const allConditionsChecked = useSelector(getIsAllConditionsCheckedSelector);
  const progressValue = useSelector(getProgressSelector);
  const list = useSelector(state => state.gifts?.list);
  const stockCode = useSelector(state => state.stock?.data?.code);
  const compositionCart = useSelector(state => state.cart?.composition);
  const compositionHasGift = useMemo(() => compositionCart?.find(good => good.type === 'bonus'), [
    compositionCart,
  ]);

  const { isPromotionGameActive, gameName, artefacts } = useSelector(state => state.promotionGame);
  const isFoolsDayGame = useRef(gameName === themeNameSpaces.FOOLS_DAY).current;
  const isMovieBorder = useSelector(isMovieBorderTheme);

  const [promocodeValue, setPromocodeValue] = useState('');
  const [isClearing, setIsClearing] = useState(false);

  const hasConditions = conditions.length > 0;
  const hasRewards = rewards.length > 0;

  const isDisabled = useMemo(() => !!stockCode, [stockCode]);

  const onPromocodeChange = e => {
    dispatch(clearStockErrors());
    setPromocodeValue(e.target.value);
  };

  const onPromocodeFocus = () => {
    dispatch(clearStockErrors());
  };

  // сбрасываем промокод, если он применен
  const onClearStock = async () => {
    setIsClearing(true);
    await dispatch(clearStock());
    setIsClearing(false);
    setPromocodeValue('');
    window.history.pushState({}, '', getRoute(paths.home));
  };
  const location = useLocation();
  const onApplyStock = async () => {
    if (promocodeValue) {
      //дизейблим кнопку применения промо через isClearing
      await setIsClearing(true);
      await dispatch(applyStock({ stock_code: promocodeValue }));
      setIsClearing(false);
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const promoCode = searchParams.get('promocode');

    if (promoCode) {
      dispatch(applyStock({ stock_code: promoCode }));
    }
  }, [location]);

  useEffect(() => {
    if (list.length > 0 && isDisabled && compositionHasGift) {
      setGlobalMessage(intl.formatMessage({ id: 'papabonus.gifts.disabled' }));
    }
  }, [list, isDisabled]);

  return (
    <div className={cx('PromocodeWidget', className)} data-test-id="promocode_widget">
      {withProgressbar && hasConditions && (
        <div className={cx('PromocodeWidget__progress')}>
          <div
            className={cx('PromocodeWidget__progress-scale')}
            style={{ transform: `scaleX(${progressValue})` }}
          />
        </div>
      )}
      {isPromotionGameActive && isFoolsDayGame && showDecor && (
        <FlagsDecor className={cx('PromocodeWidget__promoGame-decor')} bigFlags={isDesktop} />
      )}
      {isPromotionGameActive && showDecor && (
        <PromoArtefact
          activeEffect={'opacity'}
          id={'coupon'}
          className={cx('PromocodeWidget__promoGame')}
        />
      )}
      {isMovieBorder && !isDesktop && showDecor && (
        <PromotionMovieBorderDecor srcView={'1'} className={cx(`PromocodeWidget__PromoBorder`)} />
      )}
      <div className={cx('PromocodeWidget__field', inputClassname)}>
        <input
          className={cx('PromocodeWidget__input', {
            PromocodeWidget__input_applied: isApplying || isApplied,
            PromocodeWidget__input_error: error,
            PromocodeWidget__input_withoutFocus: isPromotionGameActive && showDecor,
          })}
          value={isApplied ? code : promocodeValue}
          onChange={onPromocodeChange}
          onFocus={onPromocodeFocus}
          type="text"
          placeholder={intl.formatMessage({ id: 'field.promocode.placeholder' })}
          onKeyDown={e => e.key === 'Enter' && !isApplied && onApplyStock()}
          readOnly={isApplied}
        />
        <button
          className={cx('PromocodeWidget__button', {
            PromocodeWidget__button_applied: isApplying || isApplied,
          })}
          id={'btn_promocode'}
          onClick={isApplying || isApplied ? onClearStock : onApplyStock}
          disabled={isClearing}
          type="button"
        >
          {!isApplied && !(isApplying || isClearing) && (
            <div className={cx('PromocodeWidget__button-arrow')} />
          )}
          {isApplied && !(isApplying || isClearing) && (
            <div className={cx('PromocodeWidget__button-clear')}>
              <ClearIcon />
            </div>
          )}
          {(isApplying || isClearing) && <div className={cx('PromocodeWidget__spinner')} />}
        </button>
      </div>
      {error && (
        <div className={cx('PromocodeWidget__error')} dangerouslySetInnerHTML={{ __html: error }} />
      )}
      {hasConditions && (
        <div className={cx('PromocodeWidget__conditions')}>
          {conditions.map((condition, index) => (
            <CheckItem key={index} idx={index} code={code} {...condition} />
          ))}
        </div>
      )}
      {hasRewards && (
        <div className={cx('PromocodeWidget__conditions')}>
          {rewards.map((reward, index) => (
            <RewardItem
              key={index}
              idx={index}
              {...reward}
              code={code}
              allConditionsChecked={allConditionsChecked}
            />
          ))}
        </div>
      )}
    </div>
  );
}

PromocodeWidget.defaultProps = {
  className: '',
  inputClassname: '',
  withProgressbar: true,
  showDecor: false,
};

PromocodeWidget.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  withProgressbar: PropTypes.bool,
  isDesktop: PropTypes.bool,
  showDecor: PropTypes.bool,
};
