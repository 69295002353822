import React from 'react';
import PropTypes from 'prop-types';

import img1 from './assets/1.png';
import img2 from './assets/2.png';
import img3 from './assets/3.png';
import img4 from './assets/4.png';
import img5 from './assets/5.png';

const decors = {
  1: { src: img1 },
  2: { src: img2 },
  3: { src: img3 },
  4: { src: img4 },
  8: { src: img5 },
};

const PromotionMovieBorderDecor = ({ className, srcView }) => {
  return (
    <img
      src={decors[srcView].src}
      style={{ position: 'absolute', pointerEvents: 'none' }}
      className={className}
    />
  );
};

export default PromotionMovieBorderDecor;

PromotionMovieBorderDecor.propTypes = {
  className: PropTypes.string,
  srcView: PropTypes.oneOf(['1', '2', '3', '4', '8']).isRequired,
};
